/* General styles */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

.internship-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.internship-header {
  text-align: center;
  margin-bottom: 30px;
}

.internship-title {
  font-size: 1.8em;
  color: #2c3e50;
  margin-bottom: 10px;
  font-weight: 700; /* Make the font bold */
  text-transform: uppercase; /* Convert text to uppercase for a formal look */
  letter-spacing: 1px; /* Add letter spacing for better readability */
}

.internship-subtitle {
  font-size: 1.8em;
  color: #2980b9;
  margin-bottom: 20px; /* Increase bottom margin */
  font-weight: 500; /* Make the font slightly bold */
  text-align: center; /* Center-align for a cleaner look */
  font-style: italic; /* Italicize for emphasis */
}

.internship-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.internship-card {
  background-color: #ecf0f1;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.internship-card:hover {
  transform: scale(1.05);
}

.internship-card-title {
  font-size: 1.5em;
  color: #34495e;
  margin-bottom: 10px;
}

.internship-description {
  font-size: 1em;
  line-height: 1.6;
}

/* CTA and Keywords */
.internship-cta,
.internship-keywords,
.internship-meta {
  background-color: #ecf0f1;
  padding: 15px;
  border-radius: 5px;
  margin-top: 20px;
}

.internship-cta-title,
.internship-keywords-title,
.internship-meta-title {
  font-size: 1.5em;
  color: #34495e;
  margin-bottom: 10px;
}

.internship-cta-list,
.internship-keywords-list {
  list-style-type: none;
  padding: 0;
}

.internship-cta-list li,
.internship-keywords-list li {
  padding: 5px 0;
  font-size: 1.1em;
  border-bottom: 1px solid #bdc3c7;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .internship-title {
    font-size: 2em; /* Adjust size for smaller screens */
  }

  .internship-subtitle {
    font-size: 1.5em; /* Adjust size for smaller screens */
  }

  .internship-card {
    padding: 15px;
  }
}

/* Add fade-in and fade-out effects */
.fade-in {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-out {
  opacity: 0;
  transform: translateY(20px);
}

/* Other existing styles remain unchanged */
.internship-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* Space between icons and title */
}

.internship-icon {
  margin: 0 15px; /* Space between icons */
}
.internship-register {
  text-align: center;
  margin-top: 40px;
}

.register-button {
  background-color: #2980b9;
  color: white;
  padding: 15px 30px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-button:hover {
  background-color: #1a5f84;
}
