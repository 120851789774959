.certificate-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    padding: 20px;
}

.pdf-document {
    width: 100%;
    max-width: 800px;
}

.pdf-page {
    display: flex;
    justify-content: center;
    border: 1px solid #ccc;
    margin-top: 20px;
}
