.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  font-family: 'Arial', sans-serif;
}

.about-section {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.scroll-section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.scroll-section.visible {
  opacity: 1;
  transform: translateY(0);
}

.section-title {
  font-size: 24px;
  color: #333333;
  margin-bottom: 15px;
  position: relative;
}

.section-title::after {
  content: '';
  display: block;
  height: 3px;
  width: 50px;
  background: #4caf50;
  margin-top: 5px;
}

.section-text {
  font-size: 16px;
  color: #555555;
  line-height: 1.6;
  margin-bottom: 20px;
}

.offerings-list {
  list-style-type: none;
  padding: 0;
}

.offerings-list li {
  background: #e0f7fa;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.offerings-list li:hover {
  background-color: #b2ebf2;
}

.contact-section {
  padding: 20px;
  background: #e8f5e9;
  border-radius: 5px;
  margin-top: 20px;
}

.contact-details p {
  margin: 5px 0;
}
