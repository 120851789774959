.services-container {
  font-family: 'Arial', sans-serif;
  color: #333;
}

.hero {
  background: #f4f4f4;
  padding: 2rem;
  text-align: center;
}

.hero-content h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero-content p {
  font-size: 1.2rem;
}

.cta-buttons {
  margin-top: 1.5rem;
}

.cta-primary, .cta-secondary {
  padding: 0.8rem 1.5rem;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.cta-primary {
  background-color: darkblue;
  color: white;
  margin-right: 1rem;
}

.cta-secondary {
  background-color: transparent;
  border: 2px solid darkblue;
  color: darkblue;
}

.cta-primary:hover, .cta-secondary:hover {
  background-color: darkblue;
  color: white;
}

.services-shop {
  padding: 2rem;
  text-align: center;
}

.services-grid {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.service-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 1.5rem;
  width: 300px;
  text-align: center;
}

.service-card img {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.service-card h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.quality-assurance, .final-cta {
  padding: 2rem;
  text-align: center;
  background: #fafafa;
  margin-top: 2rem;
}

.final-cta {
  background-color:darkblue;
  color: white;
}
.Shop{
  background-color:darkred;
  color: white;
}