/* Global Styles */
body {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #141414; /* Dark background for a modern look */
    color: #ffffff; /* Light text color for contrast */
}

/* Container Styles */
.blog-container {
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1); /* Slightly transparent background */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3); /* Deeper shadow for depth */
    border-radius: 12px;
    overflow: hidden; /* Prevent overflow */
}

/* Header Styles */
.blog-header {
    text-align: center;
    margin-bottom: 20px;
}

.header-image {
    width: 100%;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); /* Shadow for image depth */
}

/* Section Styles */
.blog-section {
    margin: 20px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* Subtle divider */
    padding-bottom: 20px;
}

.blog-section:last-of-type {
    border-bottom: none;
}

.toggle-header {
    cursor: pointer;
    color: #e50914; /* Netflix red color */
    font-size: 1.2em; /* Larger font size */
    transition: color 0.3s ease-in-out;
}

.toggle-header:hover {
    color: #f40612; /* Darker red on hover */
}

.toggle-content {
    margin-top: 10px;
    padding: 15px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.05); /* Transparent background for content */
    transition: all 0.3s ease-in-out;
    overflow: hidden; /* Hide overflow content */
}

/* List Styles */
.blog-section ul {
    list-style: none;
    padding: 0;
}

.blog-section li {
    padding: 10px 0; /* Increased padding for better spacing */
}

/* Footer Styles */
.blog-footer {
    margin-top: 20px;
    padding: 20px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    text-align: center;
}

/* Image Styles */
/* Image Styles - Ensuring Same Size */
.section-image, .header-image {
    width: 100%; /* Ensures image takes full width of container */
    max-width: 400px; /* Set a maximum width for consistency */
    height: 300px; /* Fixed height for all images */
    object-fit: cover; /* Ensures the image is cropped to fill the area */
    border-radius: 6px;
    margin: 10px 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5); /* Image depth */
}


/* Scrollable Section Styles */
.blog-section {
    overflow: hidden; /* Prevent content overflow on small screens */
}

/* FAQ Section Styles */
.faq-section {
    background-color: #f9f9f9; /* Light gray background */
    border: 1px solid #e0e0e0; /* Subtle border */
    border-radius: 8px; /* Rounded corners */
    padding: 20px;
    margin: 20px 0; /* Separation from other sections */
    color: #333; /* Darker text for contrast */
}

.faq-section h2 {
    font-size: 24px; /* Larger font size for FAQ title */
    margin-bottom: 15px; /* Space below title */
    color: #141414; /* Dark text for FAQ section */
}

.faq-section ul {
    list-style-type: none; /* Remove bullet points */
    padding-left: 0; /* Remove default padding */
}

.faq-section li {
    margin-bottom: 10px; /* Space between FAQ items */
    font-size: 16px; /* Standard font size for FAQ items */
    color: #141414;
}

.faq-section li strong {
    font-weight: bold; /* Bold text for questions */
    color: #e50914; /* Netflix red for highlighting questions */
}


.header-images{
    width: 100%;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}