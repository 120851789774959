.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4; /* Light grey background */
  color: #333; /* Dark text for contrast */
}

.contact-header {
  text-align: center;
  margin-bottom: 30px;
}

.contact-header h1 {
  font-size: 2.5em;
  color: #222; /* Darker color for the header */
  margin: 0;
}

.contact-header p {
  margin: 5px 0;
  color: #666; /* Slightly darker grey for subtext */
  font-size: 1.1em;
}

.contact-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  background: #fff; /* White background for the form and info section */
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  padding: 20px;
  transition: transform 0.3s;
}

.contact-content:hover {
  transform: translateY(-5px); /* Lift effect on hover */
}

.contact-left, .contact-right {
  padding: 20px;
}

.contact-left {
  flex: 2;
  margin-right: 20px;
}

.contact-right {
  flex: 1;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333; /* Darker text for labels */
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc; /* Light border for inputs */
  border-radius: 4px;
  font-size: 16px;
  background-color: #fff; /* White background for input fields */
  color: #333; /* Dark text for input fields */
  transition: border-color 0.3s, background-color 0.3s;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #007bff; /* Blue highlight on focus */
  outline: none;
}

.contact-submit {
  background-color: #007bff; /* Primary button color */
  color: white;
  border: none;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s;
}

.contact-submit:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: scale(1.05);
}

.contact-right h3 {
  margin-top: 20px;
  color: #333; /* Darker color for section headers */
}

.contact-right h4 {
  margin-top: 15px;
  color: #555; /* Slightly lighter for subsections */
}

.contact-info p {
  margin: 5px 0;
  color: #666;
}
.success-message {
  margin-top: 20px;
  color: #28a745; /* Green for success messages */
  font-weight: bold;
}
